import request from "@/common/request"
//获取拖拽图形验证码
export const captchaGet = () => {
    return request("get", "/captcha/get");
}
//验证拖拽图形验证码
export const captchaVerify = (id, width) => {
    return request("get", "/captcha/verify?captchaId=" + id + "&moveLength=" + width);
}
//获取数字图形验证码
export const numberCaptchaGet = () => {
    return request("get", "/captcha/getNumber");
}
//验证数字图形验证码
export const numberCaptchaVerify = (id, code) => {
    return request("get", "/captcha/verifyNumber?captchaId=" + id + "&code=" + code);
}

//登录的验证码配置
export const loginCaptchaConfig = () => {
    return request("get", "/captcha/loginConfig");
}