<!-- 登录页滑块 -->
<template>
  <div class="jc-component__range">
    <!-- :class="rangeStatus?'success':''" -->
    <div class="jc-range">
      <!-- rangeStatus?successIcon: -->
      <i @touchstart="rangeMove" :class="startIcon" captchaId="captchaId" ref="slider"></i>
      <!-- rangeStatus?successText: -->
      {{startText}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    captchaId: {
      type: String
    },
    // 成功之后的函数
    successFun: {
      type: Function
    },
    //成功图标
    successIcon: {
      type: String,
      default: 'el-icon-success'
    },
    //成功文字
    // successText: {
    //   type: String,
    //   default: '验证成功'
    // },
    //开始的图标
    startIcon: {
      type: String,
      default: 'el-icon-d-arrow-right'
    },
    //开始的文字
    startText: {
      type: String,
      default: '向右滑动完成验证'
    },
    //失败之后的函数
    errorFun: {
      type: Function
    },
    //或者用值来进行监听
    status: {
      type: String
    }
  },
  data () {
    return {
      disX: 0,
      rangeStatus: false
    }
  },
  created: {

  },
  methods: {

    //滑块移动
    rangeMove (e) {
      let ele = e.target
      ele.addEventListener('touchmove', this.move, { passive: false })
      ele.addEventListener('touchend', this.end, { passive: false })
    },
    move (e) {
      let ele = e.target
      let startX = 0;
      let eleWidth = 50;
      let MaxX = 260;
      let endX = e.targetTouches[0].clientX;
      this.disX = endX - startX;
      if (this.disX <= 0) {
        this.disX = 0;
      }
      if (this.disX >= MaxX - eleWidth) {//减去滑块的宽度,体验效果更好
        this.disX = MaxX;
      }
      ele.style.transition = '.1s all';
      ele.style.transform = 'translateX(' + this.disX + 'px)';
      e.preventDefault();
      this.$emit('sendMoveLength', this.disX)
    },
    end (e) {
      let ele = e.target
      setTimeout(() => {
        ele.style.transition = '.5s all';
        ele.style.transform = 'translateX(0)';
      }, 1000)
      this.successFun && this.successFun();
      window.removeEventListener('touchmove', this.move, { passive: false })
      window.removeEventListener('ontouchend', this.end, { passive: false })
      setTimeout(() => {
        this.disX = null
        this.$emit('sendMoveLength', this.disX)
      }, 1000)

    }

  }
}

</script>
<style lang="scss" scoped>
@mixin jc-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jc-component__range {
  margin-bottom: 10px;
  .jc-range {
    background-color: #e9e9e9;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #585858;
    @include jc-flex;
    height: 50px; /*no*/
    &.success {
      background-color: #3bc923;
      color: #fff;
      i {
        color: #3bc923;
      }
    }
    i {
      position: absolute;
      left: 0;
      width: 50px; /*no*/
      height: 100%;
      color: #3fcd26;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      cursor: pointer;
      font-size: 24px;
      @include jc-flex;
    }
  }
}
</style>