<template>
  <div id="container">
    <!-- LOGIN FORM -->
    <!--===================================================-->
    <div class="lock-wrapper">
      <div class="login">
        <h1>
<!--          {{ proName }}-->
          56世界
          <br v-if="isPc" />运营管理平台
        </h1>
        <p class="slogan" v-if="isPc">WELCOME TO THE SYSTEM</p>
        <div class="user" v-if="type">
          <el-form :model="loginForm" status-icon ref="loginForm" class="demo-ruleForm">
            <el-form-item
              prop="username"
              :rules="[
                { required: true, message: '请输入后台账号', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="loginForm.username"
                autocomplete="off"
                prefix-icon="el-icon-user-solid"
                placeholder="请输入后台账号"
                :clearable="false"
                @keyup.enter.native="handleNameChange('loginForm')"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="pwd"
              :rules="[
                { required: true, message: '请输入登录密码', trigger: 'blur' },
              ]"
            >
              <el-input
                type="password"
                v-model="loginForm.pwd"
                autocomplete="off"
                prefix-icon=" iconfont iframe-iconicon2"
                placeholder="请输入登录密码"
                show-password
                :clearable="false"
                @keyup.enter.native="handleNameChange('loginForm')"
              ></el-input>
            </el-form-item>
            <el-form-item class="rememberPsd" v-if="isPc">
              <el-checkbox v-model="loginForm.checked" @change="change">记住登录密码</el-checkbox>
            </el-form-item>
            <el-form-item>
              <!-- @click="toGetCaptcha('loginForm')" -->
              <el-button style="width: 100%" @click="submitForm('loginForm', 1)">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- 手机号登陆 -->
        <div class="user" v-else>
          <el-form :model="loginForm1" status-icon ref="loginForm1" class="demo-ruleForm">
            <el-form-item
              prop="phone"
              :rules="[
                { required: true, message: '请输入手机号', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="loginForm1.phone"
                autocomplete="off"
                prefix-icon="iconfont iframe-iconshouji"
                placeholder="请输入手机号"
                :clearable="false"
                @keyup.enter.native="handlePhoneChange('loginForm1')"
              ></el-input>
            </el-form-item>
            <el-form-item
              prop="pwd"
              :rules="[
                { required: true, message: '请输入密码', trigger: 'blur' },
              ]"
            >
              <el-input
                type="password"
                v-model="loginForm1.pwd"
                autocomplete="off"
                prefix-icon=" iconfont iframe-iconicon2"
                placeholder="请输入密码"
                show-password
                :clearable="false"
                @keyup.enter.native="handleNameChange('loginForm1')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="width: 100%" @click="submitForm('loginForm1', 2)">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
        <p class="phoneLogin" @click="changePhone('loginForm')" v-if="type">使用手机号登陆</p>
        <p class="phoneLogin" @click="changeCode('loginForm1')" v-if="!type">使用账号密码登陆</p>

        <div class="otherMethods">
          <img src="../../assets/img/wechat.png" mode="widthFix" />
          <img src="../../assets/img/qq.png" mode="widthFix" />
          <img src="../../assets/img/qiyeweixin.png" mode="widthFix" />
          <img src="../../assets/img/dingdinglogin.png" mode="widthFix" />
        </div>
      </div>
    </div>
    <!-- 图形拖拽验证码 -->
    <el-dialog
      title="请完成安全认证"
      :visible.sync="dialogVisible"
      width="350px"
      height="165px"
      custom-class="loginDialog"
    >
      <div
        style="
          border: 1px solid;
          margin: 3px auto;
          width: 310px;
          height: 155px;
          position: relative;
        "
      >
        <img
          style="position: absolute; display: block; top: 0; left: 0"
          :src="captcha.bigImage"
          alt="抠图"
        />
        <img :style="captcha.smallImageStyle" :src="captcha.smallImage" alt="带抠图阴影的原图" />
        <p v-if="verifications" class="verifications">验证成功</p>
        <p v-if="verification" class="verification">验证失败</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <!--  :successFun="onMpanelSuccess"
        :errorFun="onMpanelError"-->
        <slider
          @sendMoveLength="changeMove"
          :captchaId="captcha.id"
          :successFun="onMpanelSuccess"
          v-if="isPc"
        ></slider>
        <slider2
          @sendMoveLength="changeMove"
          :captchaId="captcha.id"
          :successFun="onMpanelSuccess"
          v-else
        ></slider2>
        <div class="Refresh">
          <img
            src="../../assets/img/refresh.png"
            alt
            style="width: 30px; height: 30px; cursor: pointer"
            @click.stop="refreshHandle"
          />
        </div>
        <!-- <el-slider
          v-model="captcha.xWidth"
          @input="moveCaptcha()"
          @change="verifyCaptcha('loginForm')"
        ></el-slider>-->
      </span>
    </el-dialog>
    <!-- 数字字母验证码 -->
    <el-dialog
      title
      :visible.sync="numberDialogVisible"
      width="350px"
      height="165px"
      custom-class="numDialog"
    >
      <div class="inner">
        <img :src="numImgCaptcha.image" alt="验证码图片" />
        <div class="Refresh">
          <img
            src="../../assets/img/refresh.png"
            alt
            style="width: 30px; height: 30px; cursor: pointer"
            @click.stop="refreshHandle"
          />
        </div>
      </div>
      <div class="input">
        <el-input
          v-model="numImgCaptcha.code"
          :suffix-icon="numImgCaptcha.verifyResult"
          @change="verifyNumberCaptcha()"
          placeholder="请输入验证码"
        ></el-input>
      </div>
    </el-dialog>

<!--    <div class="copyRight">copyright © 2021 {{ proName }} All rights Reserved</div>-->
    <div class="copyRight">copyright © 2021 56世界 All rights Reserved</div>
  </div>
</template>
<script>
// 引入base64
const Base64 = require("js-base64").Base64;
import { login } from "@/api/auth/auth.js";
import {
  captchaGet,
  captchaVerify,
  loginCaptchaConfig,
  numberCaptchaGet,
  numberCaptchaVerify,
} from "@/api/auth/captcha.js";
import { prefixFileUrl } from "@/api/public.api.js";
import md5 from "crypto-js/md5";
import slider from "@/components/fortress-slider.vue";
import slider2 from "@/components/fortress-slider2.vue";
export default {
  data () {
    return {
      // mobile: true,
      offsetWidth: "",
      verification: false,
      verifications: false,
      type: true,
      proName: window.$conf.commonConf.projectName,
      loginForm: {
        username: "",
        pwd: "",
        checked: false,
      },
      loginForm1: {
        phone: "",
        pwd: "",
      },
      commonStyle: "position: absolute;z-index:999; display: block;",
      captcha: {
        id: "",
        bigImage: "",
        smallImage: "",
        yHeight: 0,
        xWidth: 0,
        smallImageStyle: "",
        moveLength: 0,
      },
      dialogVisible: false,
      isPc: true,
      loginType: 1,
      captchaConfig: {
        isOpen: true,
        imageType: 1,
      },
      numberDialogVisible: false,
      numImgCaptcha: {
        id: "",
        image: "",
        code: "",
        verifyResult: "",
      },
    };
  },
  components: {
    slider,
    slider2,
  },
  created () {
    this.offsetWidth = document.body.offsetWidth;
    if (+this.offsetWidth < 700) {
      this.isPc = false;
    } else {
      this.isPc = true;
    }
    // this.isMobileHandle()
    // 在页面加载时从cookie获取登录信息
    let username = this.getCookie("username");
    let pwd = Base64.decode(this.getCookie("pwd"));
    if (username) {
      this.loginForm.username = username;
      this.loginForm.pwd = pwd;
      this.loginForm.checked = true;
    }
    //获取验证码方式
    loginCaptchaConfig().then((resp) => {
      if (resp.code == 0) {
        this.captchaConfig.isOpen = resp.data.isOpen;
        this.captchaConfig.imageType = resp.data.imageType;
      }
    });
  },
  mounted () {
    window.onresize = () => {
      return (() => {
        this.offsetWidth = document.body.offsetWidth;
        if (+this.offsetWidth < 700) {
          this.isPc = false;
        } else {
          this.isPc = true;
        }
        // this.isMobileHandle()
      })();
    };
  },
  methods: {
    //刷新验证图片
    refreshHandle () {
      if (this.captchaConfig.isOpen && this.captchaConfig.imageType == 2) {
        this.verification = false;
        this.getCaptcha();
      } else if (
        this.captchaConfig.isOpen &&
        this.captchaConfig.imageType == 1
      ) {
        this.numImgCaptcha.verifyResult = "";
        this.numImgCaptcha.code = "";
        this.getNumberCaptcha();
      }
    },
    //判断是浏览器还是移动端
    isMobileHandle () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (flag) {
        this.isPc = false;
      } else {
        this.isPc = true;
      }
    },
    changeMove (data) {
      this.captcha.xWidth = data;
      this.captcha.moveLength = this.captcha.xWidth;
      this.captcha.smallImageStyle =
        this.commonStyle +
        "top:" +
        this.captcha.yHeight +
        "px;left:" +
        this.captcha.moveLength +
        "px;";
    },
    changePhone (formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.type = false;
    },
    changeCode (formName) {
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
      this.type = true;
    },
    change (e) {
      if (e) {
        this.setCookie("username", this.loginForm.username);
        // base64加密密码
        let passWord = Base64.encode(this.loginForm.pwd);
        this.setCookie("pwd", passWord);
      } else {
        this.setCookie("username", "");
        this.setCookie("pwd", "");
      }
    },
    handleNameChange (formName) {
      this.submitForm(formName);
    },
    handlePhoneChange (formName) {
      this.submitForm(formName);
    },
    //校验验证码然后登陆///4
    onMpanelSuccess () {
      this.verifyCaptcha("loginForm");
    },
    //5
    submitForm (formName, num) {
      this.loginType = num;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.captchaConfig.isOpen && this.captchaConfig.imageType == 2) {
            this.toGetCaptcha();
          } else if (
            this.captchaConfig.isOpen &&
            this.captchaConfig.imageType == 1
          ) {
            this.toGetNumberCaptcha();
          } else if (!this.captchaConfig.isOpen) {
            this.doLogin();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
        // 储存登录信息
        this.setUserInfo();
      });
    },
    initPrefixUrl () {
      prefixFileUrl().then((resp) => {
        if (resp.code == 0) {
          if (resp.data) {
            this.$store.commit("setPrefixUrl", resp.data);
          }
        }
      });
    },
    //登录，与后台交互
    doLogin () {
      if (this.loginType == 1) {
        this.loginForm.username = this.loginForm.username;
        this.loginForm.pwd = this.loginForm.pwd;
      }
      if (this.loginType == 2) {
        this.loginForm.username = this.loginForm1.phone;
        this.loginForm.pwd = this.loginForm1.pwd;
      }
      login(this.loginForm.username, md5(this.loginForm.pwd)).then((resp) => {
        if (resp.code == 0) {
          this.initPrefixUrl();
          this.$store.commit("setToken", resp.data.token);
          localStorage.setItem("fortressExpiredTime", resp.data.expiredTime);
          localStorage.setItem("fortressRefreshToken", resp.data.refreshToken);
          //登录成功后 保存token信息 并跳转到home页
          this.$router.push({ name: "home" });
          this.$store.dispatch("initRouter");
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
      if (this.verifications) {
        this.verifications = false;
      }
      if (this.dialogVisible) {
        this.dialogVisible = false;
      }
      if (this.numberDialogVisible) {
        this.numberDialogVisible = false;
      }
    },
    // 储存表单信息
    setUserInfo: function () {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.loginForm.checked) {
        this.setCookie("username", this.loginForm.username);
        // base64加密密码
        let passWord = Base64.encode(this.loginForm.pwd);
        this.setCookie("pwd", passWord);
      } else {
        this.setCookie("username", "");
        this.setCookie("pwd", "");
      }
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie: function (cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },

    ///2
    getCaptcha () {
      captchaGet().then((resp) => {
        if (resp.code == 0) {
          this.captcha.id = resp.data.captchaId;
          this.captcha.bigImage = "data:image/png;base64," + resp.data.bigImage;
          this.captcha.smallImage =
            "data:image/png;base64," + resp.data.smallImage;
          this.captcha.yHeight = resp.data.yHeight;
          this.captcha.xWidth = 0;
          this.captcha.moveLength = 0;
          this.captcha.smallImageStyle =
            this.commonStyle +
            "top:" +
            this.captcha.yHeight +
            "px;left:" +
            this.captcha.xWidth +
            "px;";
        }
      });
    },
    //打开验证码弹出框 ////1
    toGetCaptcha () {
      this.dialogVisible = true;
      this.getCaptcha();
    },
    //拖动滑块 ///3
    moveCaptcha () {
      this.captcha.moveLength = (this.captcha.xWidth * 310) / 100;
      this.captcha.smallImageStyle =
        this.commonStyle +
        "top:" +
        this.captcha.yHeight +
        "px;left:" +
        this.captcha.moveLength +
        "px;";
    },
    //校验验证码
    verifyCaptcha () {
      captchaVerify(this.captcha.id, this.captcha.moveLength).then((resp) => {
        if (resp.code == 0) {
          this.verifications = true;
          let self = this;
          setTimeout(() => {
            self.doLogin();
          }, 1000);
        } else {
          this.verification = true;
          setTimeout(() => {
            this.verification = false;
            this.getCaptcha();
          }, 1000);
        }
      });
    },

    //打开数字图形验证码弹出框
    toGetNumberCaptcha () {
      this.numberDialogVisible = true;
      this.getNumberCaptcha();
    },
    //获取数字图形验证码
    getNumberCaptcha () {
      numberCaptchaGet().then((resp) => {
        if (resp.code == 0) {
          this.numImgCaptcha.id = resp.data.captchaId;
          this.numImgCaptcha.image = "data:image/png;base64," + resp.data.image;
        }
      });
    },
    //校验数字图形验证码
    verifyNumberCaptcha () {
      if (!this.numImgCaptcha.code) {
        this.$msg({
          message: "请输入验证码",
          type: "error",
        });
        return;
      }
      numberCaptchaVerify(this.numImgCaptcha.id, this.numImgCaptcha.code).then(
        (resp) => {
          if (resp.code == 0) {
            this.numImgCaptcha.verifyResult = "el-icon-check";
            if (this.loginType == 1) {
              this.loginForm.username = this.loginForm.username;
              this.loginForm.pwd = this.loginForm.pwd;
            }
            if (this.loginType == 2) {
              this.loginForm.username = this.loginForm1.phone;
              this.loginForm.pwd = this.loginForm1.pwd;
            }
            let self = this;
            setTimeout(() => {
              self.doLogin();
            }, 500);
          } else {
            this.numImgCaptcha.verifyResult = "el-icon-close";
            setTimeout(() => {
              this.numImgCaptcha.verifyResult = "";
              this.numImgCaptcha.code = "";
              this.getNumberCaptcha();
            }, 500);
          }
        }
      );
    },
  },
};
</script>
<style>
.loginDialog .el-dialog__body {
  padding: 0;
}
.loginDialog .el-dialog__footer {
  padding: 8px 20px;
}
.numDialog .el-dialog__body {
  padding: 10px 20px 30px;
}
</style>
<style scoped lang="scss">
.copyRight {
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
  text-align: center;
  font-size: 15px;
  color: #6e9cff;
}
@media screen and (min-width: 700px) {
  #container {
    background: url('../../assets/img/login_bg.png') no-repeat center top /
      cover;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .lock-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transform: translate(-50%, -50%);
    z-index: 99;
    zoom: 1;
    background-color: #fff;
    background: url(../../assets/img/login_inner.png) no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    .login {
      width: 400px;
      height: 408px;
      // padding-right: 75px;
      // padding: 150px 75px 100px 0;
      box-sizing: border-box;
      position: relative;
      h1 {
        font-size: 28px;
        color: #1ccae9;
        margin-bottom: 18px;
        text-align: center;
        line-height: 1.3;
      }
      .slogan {
        margin-bottom: 20px;
        font-size: 18px;
        color: #858585;
        text-align: center;
        line-height: 1;
      }
      .user {
        width: 300px;
        margin: 0 auto;
      }
      .user /deep/ .el-form-item {
        margin-bottom: 20px;
      }
      .user /deep/ .el-input .el-input__inner {
        background: #f7f7f7;
        border: none;
      }
      .user /deep/ .el-input .el-input__inner {
        background: #f7f7f7;
        border: none;
      }
      .user /deep/ .el-button {
        color: #fff;
        background: #14bddb;
        border: none;
      }
      .user /deep/ .el-button:hover,
      .user /deep/ .el-button:focus {
        color: #fff !important;
        background: #14bddb !important;
        border: none !important;
        opacity: 0.8;
      }
      .user /deep/ .el-input__prefix {
        height: 100%;
        width: 25px;
        text-align: center;
      }
      .rememberPsd /deep/ .el-form-item__content {
        line-height: 1;
      }
      .rememberPsd /deep/ label {
        margin-bottom: 0;
      }
      .rememberPsd /deep/ .el-checkbox__inner {
        border: 1px solid #dcdfe6 !important;
      }
      .rememberPsd /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        border: 1px solid #14bddb !important;
      }
      .rememberPsd /deep/ .el-checkbox__input + .el-checkbox__label {
        color: #999 !important;
      }
      .rememberPsd /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #999 !important;
      }
      .sendCode /deep/ .el-input-group__append {
        border: none;
        padding: 0 10px;
        color: #999;
        /* border: 1px solid; */
        /* height: 20px; */
        background: #f7f7f7;
      }
      .phoneLogin {
        margin-top: -12px;
        text-align: right;
        padding-right: 48px;
        font-size: 14px;
        color: #777;
        cursor: pointer;
      }
      .otherMethods {
        width: 100%;
        padding: 0 20px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 10px;
        left: 0;
        image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .verification {
    position: absolute;
    bottom: -10px;
    left: -2px;
    z-index: 999;
    width: 102%;
    height: 50px;
    line-height: 50px;
    color: red;
    text-align: center;
    font-size: 16px;
    transition: height 0.2s;
    background: rgba(255, 255, 255, 0.5);
  }
  .verifications {
    position: absolute;
    bottom: -10px;
    left: -2px;
    z-index: 999;
    width: 102%;
    height: 50px;
    line-height: 50px;
    color: green;
    text-align: center;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.5);
    transition: height 0.2s;
  }
  @media screen and (max-width: 1400px) {
    .lock-wrapper {
      min-width: 900px;
      min-height: 540px;
    }
  }
  @media screen and (min-width: 1400px) {
    .lock-wrapper {
      min-width: 1080px;
      min-height: 650px;
      .login {
        width: 500px;
        height: 408px;
        .otherMethods {
          padding: 0 80px;
        }
      }
    }
  }
}
@media screen and (max-width: 699px) {
  #container {
    background: url('../../assets/img/mobile_bg.png') no-repeat center top /
      cover;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .lock-wrapper {
    width: 325px;
    height: 491.5px;
    position: fixed;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 99;
    zoom: 1;
    background-color: #fff;
    background: url(../../assets/img/mobile_inner.png) no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
    .login {
      width: 300px;
      height: 228px;

      // padding-right: 75px;
      // padding: 150px 75px 100px 0;
      box-sizing: border-box;
      position: relative;
      top: 220px;
      h1 {
        width: 100%;
        font-size: 24px;
        color: #1ccae9;
        text-align: center;
        line-height: 1.3;
        position: fixed;
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
      .slogan {
        margin-bottom: 20px;
        font-size: 18px;
        color: #858585;
        text-align: center;
        line-height: 1;
      }
      .user {
        width: 250px;
        margin: 0 auto;
      }
      .user /deep/ .el-form-item {
        margin-bottom: 20px;
      }
      .user /deep/ .el-input .el-input__inner {
        background: #f7f7f7;
        border: none;
      }
      .user /deep/ .el-input .el-input__inner {
        background: #f7f7f7;
        border: none;
      }
      .user /deep/ .el-button {
        color: #fff;
        background: #14bddb;
        border: none;
      }
      .user /deep/ .el-button:hover,
      .user /deep/ .el-button:focus {
        color: #fff !important;
        background: #14bddb !important;
        border: none !important;
        opacity: 0.8;
      }
      .user /deep/ .el-input__prefix {
        height: 100%;
        width: 25px;
        text-align: center;
      }
      .rememberPsd /deep/ .el-form-item__content {
        line-height: 1;
      }
      .rememberPsd /deep/ label {
        margin-bottom: 0;
      }
      .rememberPsd /deep/ .el-checkbox__inner {
        border: 1px solid #dcdfe6 !important;
      }
      .rememberPsd /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
        border: 1px solid #14bddb !important;
      }
      .rememberPsd /deep/ .el-checkbox__input + .el-checkbox__label {
        color: #999 !important;
      }
      .rememberPsd /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #999 !important;
      }
      .sendCode /deep/ .el-input-group__append {
        border: none;
        padding: 0 10px;
        color: #999;
        /* border: 1px solid; */
        /* height: 20px; */
        background: #f7f7f7;
      }
      .phoneLogin {
        margin-top: -12px;
        text-align: right;
        padding-right: 26px;
        font-size: 14px;
        color: #777;
        cursor: pointer;
      }
      .otherMethods {
        width: 100%;
        // padding: 0 20px;
        height: 25px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 6px;
        left: 0;
        image {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .verification {
    position: absolute;
    bottom: -10px;
    left: -2px;
    z-index: 999;
    width: 102%;
    height: 50px;
    line-height: 50px;
    color: red;
    text-align: center;
    font-size: 16px;
    transition: height 0.2s;
    background: rgba(255, 255, 255, 0.5);
  }
  .verifications {
    position: absolute;
    bottom: -10px;
    left: -2px;
    z-index: 999;
    width: 102%;
    height: 50px;
    line-height: 50px;
    color: green;
    text-align: center;
    font-size: 16px;
    background: rgba(255, 255, 255, 0.5);
    transition: height 0.2s;
  }
}
.numDialog {
  padding: 10px;

  .inner {
    padding: 24px;
    border: 1px solid;
    margin: 3px auto 10px;
    width: 310px;
    height: 155px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .Refresh {
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }
  .code {
    margin-top: 10px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #333;
    background: #e9e9e9;
    border: 1px solid #eee;
    border-radius: 2px;
  }
}
</style>
