<!-- 登录页滑块 -->
<template>
  <div class="jc-component__range">
    <!-- :class="rangeStatus?'success':''" -->
    <div class="jc-range">
      <!-- rangeStatus?successIcon: -->
      <i @mousedown="rangeMove" :class="startIcon" captchaId="captchaId"></i>
      <!-- rangeStatus?successText: -->
      {{startText}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    captchaId: {
      type: String
    },
    // 成功之后的函数
    successFun: {
      type: Function
    },
    //成功图标
    successIcon: {
      type: String,
      default: 'el-icon-success'
    },
    //成功文字
    // successText: {
    //   type: String,
    //   default: '验证成功'
    // },
    //开始的图标
    startIcon: {
      type: String,
      default: 'el-icon-d-arrow-right'
    },
    //开始的文字
    startText: {
      type: String,
      default: '向右滑动完成验证'
    },
    //失败之后的函数
    errorFun: {
      type: Function
    },
    //或者用值来进行监听
    status: {
      type: String
    }
  },
  data () {
    return {
      disX: 0,
      rangeStatus: false
    }
  },
  created: {

  },
  methods: {

    //滑块移动
    rangeMove (e) {
      let ele = e.target;
      let startX = e.clientX;
      let eleWidth = ele.offsetWidth;
      let parentWidth = ele.parentElement.offsetWidth;
      let MaxX = parentWidth - eleWidth;
      if (this.rangeStatus) {//不运行
        return false;
      }
      document.onmousemove = (e) => {
        let endX = e.clientX;
        this.disX = endX - startX;
        if (this.disX <= 0) {
          this.disX = 0;
        }
        if (this.disX >= MaxX) {//减去滑块的宽度,体验效果更好
          this.disX = MaxX;
        }
        ele.style.transition = '.1s all';
        ele.style.transform = 'translateX(' + this.disX + 'px)';
        e.preventDefault();
        this.$emit('sendMoveLength', this.disX, false)
      }
      document.onmouseup = () => {
        setTimeout(() => {
          ele.style.transition = '.5s all';
          ele.style.transform = 'translateX(0)';
        }, 1000)
        document.onmousemove = null;
        document.onmouseup = null;
        this.successFun && this.successFun();
        setTimeout(() => {
          this.disX = null
          this.$emit('sendMoveLength', this.disX, true)
        }, 1000)

      }
    },

  }
}

</script>
<style lang="scss" scoped>
@mixin jc-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jc-component__range {
  margin-bottom: 10px;
  .jc-range {
    background-color: #e9e9e9;
    position: relative;
    transition: 1s all;
    user-select: none;
    color: #585858;
    @include jc-flex;
    height: 50px; /*no*/
    &.success {
      background-color: #3bc923;
      color: #fff;
      i {
        color: #3bc923;
      }
    }
    i {
      position: absolute;
      left: 0;
      width: 55px; /*no*/
      height: 100%;
      color: #3fcd26;
      background-color: #fff;
      border: 1px solid #d8d8d8;
      cursor: pointer;
      font-size: 24px;
      @include jc-flex;
    }
  }
}
</style>